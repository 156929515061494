import revive_payload_client_4JudZlsl0M from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._puc57q6ppg6sfmb6glyngxauaa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_GgYelKqgaj from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._puc57q6ppg6sfmb6glyngxauaa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_542Bp3B0Pk from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._puc57q6ppg6sfmb6glyngxauaa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_nYuIVTZRht from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.5_rollup@4.21.1_vite@5.2.10_@types+node@22.5.1_terser@5._7sm3jjoqp6x5hwn4i7gaitrqm4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_ug5aLBlpPi from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._puc57q6ppg6sfmb6glyngxauaa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_lOxEvnmRJ6 from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._puc57q6ppg6sfmb6glyngxauaa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_4X4por62zn from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._puc57q6ppg6sfmb6glyngxauaa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ncma6BSHDG from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.21.1_webpack-sources@3.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_aGF52ZUvCv from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@4.21.1_vue@3.4.25_typescript@5.4.5__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_LsyAylN5VW from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@4.21.1_vue@3.4.25_typescript@5.4.5__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import formkitPlugin_pZqjah0RUG from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/.nuxt/formkitPlugin.mjs";
import plugin_4RduVJ1nmH from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.1.1_graphql-ws@5.16.0_graphql@16.8.1__react-dom@18.3.0_react@_p5o7bzwuyu3jzhvzpimjdsxyey/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.js";
import plugin_vc0hkvfot6 from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.1_encoding@0.1.13_magicast@0.3.5_next-auth@4.21.1_next@13.5.6_@babel+_oi2ner7b6ythofl2u3ebijtkzm/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_I9GqR7ERiL from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node_idotaysc6wtvj5j3oir6jv3dzy/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_XH4YyV53jE from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/nuxt-cookie-first@0.1.8_magicast@0.3.5_rollup@4.21.1_vite@5.2.10_@types+node@22.5.1_terser@5.31.6__webpack-sources@3.2.3/node_modules/nuxt-cookie-first/dist/runtime/plugin.client.js";
import plugin_yRCalT2J4e from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/@nuxt+icon@1.5.2_magicast@0.3.5_rollup@4.21.1_vite@5.2.10_@types+node@22.5.1_terser@5.31.6__v_zpxees73kzhsar22tfskbjlsei/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import chunk_reload_client_Si3iwDfI1I from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._puc57q6ppg6sfmb6glyngxauaa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import errors_pYDVX9hi9h from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_magicast@0.3.5_nuxt@3.11.2_@parcel+watc_6sgnl7ijdrqutt6xb7jdthhgga/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_XG8XZW1kOt from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_magicast@0.3.5_nuxt@3.11.2_@parcel+watc_6sgnl7ijdrqutt6xb7jdthhgga/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import apollo_VK6PXBSimI from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.0_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.8.1__magi_3p3jvhbsghqdxm5gq3rn7am6vy/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import sofie_required_version_M2FMoPhKiI from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.0_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.8.1__magi_3p3jvhbsghqdxm5gq3rn7am6vy/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/sofie-required-version.ts";
import apollo_nmBf8zrSAt from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.4.1_@bugsnag+core@7.25.0_encoding@0.1.13_graphql-ws@5.16.0_jz5vmowoly5vn53zgr6apuvecy/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/apollo.ts";
import auth_AVHqjFGbPw from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.4.1_@bugsnag+core@7.25.0_encoding@0.1.13_graphql-ws@5.16.0_jz5vmowoly5vn53zgr6apuvecy/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/auth.ts";
import markerIo_client_VW390V1LCb from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/plugins/markerIo.client.ts";
import notifications_Wskzy5ZO71 from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/plugins/notifications.ts";
export default [
  revive_payload_client_4JudZlsl0M,
  unhead_GgYelKqgaj,
  router_542Bp3B0Pk,
  _0_siteConfig_nYuIVTZRht,
  payload_client_ug5aLBlpPi,
  check_outdated_build_client_lOxEvnmRJ6,
  components_plugin_KR1HBZs4kY,
  prefetch_client_4X4por62zn,
  plugin_ncma6BSHDG,
  switch_locale_path_ssr_aGF52ZUvCv,
  i18n_LsyAylN5VW,
  formkitPlugin_pZqjah0RUG,
  plugin_4RduVJ1nmH,
  plugin_vc0hkvfot6,
  plugin_I9GqR7ERiL,
  plugin_client_XH4YyV53jE,
  plugin_yRCalT2J4e,
  chunk_reload_client_Si3iwDfI1I,
  errors_pYDVX9hi9h,
  i18n_XG8XZW1kOt,
  apollo_VK6PXBSimI,
  sofie_required_version_M2FMoPhKiI,
  apollo_nmBf8zrSAt,
  auth_AVHqjFGbPw,
  markerIo_client_VW390V1LCb,
  notifications_Wskzy5ZO71
]