<template>
  <component
    :is="modal.component"
    v-bind="modal.props"
    v-on="modal.events"
  />
</template>

<script setup lang="ts">
import {type ModalInstance, ModalInstanceSymbol} from '~/composables/modal';

const props = defineProps<{
  modal: ModalInstance
}>();

provide<ModalInstance>(ModalInstanceSymbol, props.modal);
</script>
