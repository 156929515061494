<template>
  <NuxtLink
    :to
    class="block w-fit rounded-full text-center font-Satoshi text-base font-medium leading-none text-black transition-all"
    :class="linkVariation[variation]"
  >
    <slot />
  </NuxtLink>
</template>

<script setup lang="ts">
import type {RouteLocationRaw} from 'vue-router';

withDefaults(defineProps<{
  to: RouteLocationRaw
  variation?: 'primary' | 'white' | 'outlineWhite' | 'menu' | 'link' | 'outlineBlack' | 'blue'
}>(), {
  variation: 'link',
});

const linkVariation = {
  primary: 'px-3 py-4 bg-primary hover:bg-secondary',
  white: 'px-3 py-4 bg-white hover:bg-secondary',
  outlineWhite: 'px-3 py-4 border border-white text-white hover:bg-white hover:text-black',
  outlineBlack: 'px-3 py-4 border border-black text-black hover:underline',
  menu: 'px-3 py-4 hover:bg-secondary hover:text-black text-white',
  link: 'underline',
  blue: 'bg-tertiary px-3 py-4 hover:bg-primary',
};
</script>

