<template>
  <div
    class="rounded-3xl p-4 py-6 sm:p-6 md:p-10"
    :class="cardVariation[variation]"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
defineProps<{
  variation: 'green' | 'white' | 'grey' | 'blue'
}>();

const cardVariation = {
  green: 'bg-primary',
  blue: 'bg-tertiary',
  white: 'bg-white',
  grey: 'bg-neutral-800',
};
</script>
