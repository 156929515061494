import validate from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._puc57q6ppg6sfmb6glyngxauaa/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_magicast@0.3.5_nuxt@3.11.2_@parcel+watc_6sgnl7ijdrqutt6xb7jdthhgga/node_modules/@bttr-devs/nuxt-layer-base/middleware/redirect-trailing-slash.global.ts";
import manifest_45route_45rule from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._puc57q6ppg6sfmb6glyngxauaa/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sofie-page": () => import("/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.0_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.8.1__magi_3p3jvhbsghqdxm5gq3rn7am6vy/node_modules/@bttr-devs/nuxt-layer-sofie/middleware/sofie-page.ts"),
  auth: () => import("/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.1_encoding@0.1.13_magicast@0.3.5_next-auth@4.21.1_next@13.5.6_@babel+_oi2ner7b6ythofl2u3ebijtkzm/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}