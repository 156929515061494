import markerSDK from '@marker.io/browser';
export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  if (!config.public.marker) {
    return;
  }

  markerSDK.loadWidget({
    project: config.public.marker,
  });
});
