<template>
  <div
    v-if="!isSeen"
    class="fixed right-12 top-36 z-50 hidden max-w-96 lg:flex"
  >
    <UiCard
      variation="white"
      class="relative space-y-4 shadow-2xl"
    >
      <button
        class="absolute right-4 top-4"
        type="button"
        @click="isSeen = true"
      >
        <Icon
          name="mdi:close"
          class="size-6"
        />
      </button>
      <p class="font-Bebas text-4xl font-bold">
        Welkom op Foodyard 2.0!
      </p>
      <p>Maak nu je nieuwe account aan. Ook als je al een account had.</p>
      <UiLink
        variation="primary"
        :to="{name: 'register'}"
      >
        Account aanmaken
      </UiLink>
    </UiCard>
  </div>
</template>

<script setup lang="ts">
import {useStorage} from '@vueuse/core';

const isSeen = useStorage('seen', false);
</script>
