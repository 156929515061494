<template>
  <NuxtLayout>
    <div class="mb-10 flex flex-col items-center gap-6">
      <h1 class="text-6xl text-white lg:text-[28rem]">
        {{ error.statusCode }}
      </h1>
      <FullWidthText :text="error.message" />

      <button
        class="rounded-full bg-primary p-4 text-black transition-all hover:bg-secondary"
        @click="handleError"
      >
        Terug naar home
      </button>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
const props = defineProps<{
  error: {
    message: string
    statusCode: number
  },
}>();

useSeoMeta({
  title: props.error.message,
});

function handleError() {
  clearError({redirect: '/'});
}
</script>
