<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <AppBanner />

    <ModalInstance
      v-for="modal in modals.modals.value"
      :key="modal.id"
      :modal="modal"
    />

    <Notifications
      position="top center"
      class="mt-5"
    >
      <template #body="{item, close}">
        <div
          class="mb-2.5 cursor-pointer rounded-lg bg-primary p-2.5 text-center text-black"
          @click="close"
        >
          <p class="m-0 text-sm">
            {{ item.title }}
          </p>
          <div v-text="item.text" />
        </div>
      </template>
    </Notifications>
  </div>
</template>

<script setup lang="ts">
useHead(computed(() => ({
  htmlAttrs: {lang:'nl'},
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Foodyard` : 'Foodyard';
  },
  charset: 'utf-8',
})));
const modals = useModal();
</script>
