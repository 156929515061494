import { default as _91_46_46_46page_93R1MaFQmlYCMeta } from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/[...page].vue?macro=true";
import { default as change_45passwordWplYqyDAPBMeta } from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/account/change-password.vue?macro=true";
import { default as indexjeAubhL0hMMeta } from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/account/index.vue?macro=true";
import { default as agendae5n7NEb2OEMeta } from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/agenda.vue?macro=true";
import { default as feedbacknl1Kjx7jSFMeta } from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/event/[slug]/feedback.vue?macro=true";
import { default as indexEjcrE7Q4v6Meta } from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/event/[slug]/index.vue?macro=true";
import { default as attend5m6wjOBKR7Meta } from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/event/attend.vue?macro=true";
import { default as indexqSutQCBkXyMeta } from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/event/index.vue?macro=true";
import { default as signoutB0J2FcGmnqMeta } from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/event/signout.vue?macro=true";
import { default as gemistykudvIggjxMeta } from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/gemist.vue?macro=true";
import { default as indexSMs0J0K9vkMeta } from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/index.vue?macro=true";
import { default as locatiesk8kwIHvZg6Meta } from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/locaties.vue?macro=true";
import { default as logindpuGZkds23Meta } from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/login.vue?macro=true";
import { default as registerokRM0MfOvMMeta } from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/register.vue?macro=true";
import { default as _91token_93CsJlIAfDCMMeta } from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/wachtwoord-reset/[token].vue?macro=true";
import { default as aanvraagPbjGJIFBc3Meta } from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/wachtwoord-reset/aanvraag.vue?macro=true";
import { default as zoekenW11G0Ht40rMeta } from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/zoeken.vue?macro=true";
import { default as nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta } from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._puc57q6ppg6sfmb6glyngxauaa/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPX } from "/data/www/foodyard.nl/bidfood-foodyard/nuxt/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._puc57q6ppg6sfmb6glyngxauaa/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _91_46_46_46page_93R1MaFQmlYCMeta?.name ?? "page",
    path: _91_46_46_46page_93R1MaFQmlYCMeta?.path ?? "/:page(.*)*",
    meta: _91_46_46_46page_93R1MaFQmlYCMeta || {},
    alias: _91_46_46_46page_93R1MaFQmlYCMeta?.alias || [],
    redirect: _91_46_46_46page_93R1MaFQmlYCMeta?.redirect,
    component: () => import("/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: change_45passwordWplYqyDAPBMeta?.name ?? "account-change-password",
    path: change_45passwordWplYqyDAPBMeta?.path ?? "/account/change-password",
    meta: change_45passwordWplYqyDAPBMeta || {},
    alias: change_45passwordWplYqyDAPBMeta?.alias || [],
    redirect: change_45passwordWplYqyDAPBMeta?.redirect,
    component: () => import("/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/account/change-password.vue").then(m => m.default || m)
  },
  {
    name: indexjeAubhL0hMMeta?.name ?? "account",
    path: indexjeAubhL0hMMeta?.path ?? "/account",
    meta: indexjeAubhL0hMMeta || {},
    alias: indexjeAubhL0hMMeta?.alias || [],
    redirect: indexjeAubhL0hMMeta?.redirect,
    component: () => import("/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: agendae5n7NEb2OEMeta?.name ?? "agenda",
    path: agendae5n7NEb2OEMeta?.path ?? "/agenda",
    meta: agendae5n7NEb2OEMeta || {},
    alias: agendae5n7NEb2OEMeta?.alias || [],
    redirect: agendae5n7NEb2OEMeta?.redirect,
    component: () => import("/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/agenda.vue").then(m => m.default || m)
  },
  {
    name: feedbacknl1Kjx7jSFMeta?.name ?? "event-slug-feedback",
    path: feedbacknl1Kjx7jSFMeta?.path ?? "/event/:slug()/feedback",
    meta: feedbacknl1Kjx7jSFMeta || {},
    alias: feedbacknl1Kjx7jSFMeta?.alias || [],
    redirect: feedbacknl1Kjx7jSFMeta?.redirect,
    component: () => import("/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/event/[slug]/feedback.vue").then(m => m.default || m)
  },
  {
    name: indexEjcrE7Q4v6Meta?.name ?? "event-slug",
    path: indexEjcrE7Q4v6Meta?.path ?? "/event/:slug()",
    meta: indexEjcrE7Q4v6Meta || {},
    alias: indexEjcrE7Q4v6Meta?.alias || [],
    redirect: indexEjcrE7Q4v6Meta?.redirect,
    component: () => import("/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/event/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: attend5m6wjOBKR7Meta?.name ?? "event-attend",
    path: attend5m6wjOBKR7Meta?.path ?? "/event/attend",
    meta: attend5m6wjOBKR7Meta || {},
    alias: attend5m6wjOBKR7Meta?.alias || [],
    redirect: attend5m6wjOBKR7Meta?.redirect,
    component: () => import("/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/event/attend.vue").then(m => m.default || m)
  },
  {
    name: indexqSutQCBkXyMeta?.name ?? "event",
    path: indexqSutQCBkXyMeta?.path ?? "/event",
    meta: indexqSutQCBkXyMeta || {},
    alias: indexqSutQCBkXyMeta?.alias || [],
    redirect: indexqSutQCBkXyMeta?.redirect,
    component: () => import("/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/event/index.vue").then(m => m.default || m)
  },
  {
    name: signoutB0J2FcGmnqMeta?.name ?? "event-signout",
    path: signoutB0J2FcGmnqMeta?.path ?? "/event/signout",
    meta: signoutB0J2FcGmnqMeta || {},
    alias: signoutB0J2FcGmnqMeta?.alias || [],
    redirect: signoutB0J2FcGmnqMeta?.redirect,
    component: () => import("/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/event/signout.vue").then(m => m.default || m)
  },
  {
    name: gemistykudvIggjxMeta?.name ?? "gemist",
    path: gemistykudvIggjxMeta?.path ?? "/gemist",
    meta: gemistykudvIggjxMeta || {},
    alias: gemistykudvIggjxMeta?.alias || [],
    redirect: gemistykudvIggjxMeta?.redirect,
    component: () => import("/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/gemist.vue").then(m => m.default || m)
  },
  {
    name: indexSMs0J0K9vkMeta?.name ?? "index",
    path: indexSMs0J0K9vkMeta?.path ?? "/",
    meta: indexSMs0J0K9vkMeta || {},
    alias: indexSMs0J0K9vkMeta?.alias || [],
    redirect: indexSMs0J0K9vkMeta?.redirect,
    component: () => import("/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: locatiesk8kwIHvZg6Meta?.name ?? "locaties",
    path: locatiesk8kwIHvZg6Meta?.path ?? "/locaties",
    meta: locatiesk8kwIHvZg6Meta || {},
    alias: locatiesk8kwIHvZg6Meta?.alias || [],
    redirect: locatiesk8kwIHvZg6Meta?.redirect,
    component: () => import("/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/locaties.vue").then(m => m.default || m)
  },
  {
    name: logindpuGZkds23Meta?.name ?? "login",
    path: logindpuGZkds23Meta?.path ?? "/login",
    meta: logindpuGZkds23Meta || {},
    alias: logindpuGZkds23Meta?.alias || [],
    redirect: logindpuGZkds23Meta?.redirect,
    component: () => import("/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/login.vue").then(m => m.default || m)
  },
  {
    name: registerokRM0MfOvMMeta?.name ?? "register",
    path: registerokRM0MfOvMMeta?.path ?? "/register",
    meta: registerokRM0MfOvMMeta || {},
    alias: registerokRM0MfOvMMeta?.alias || [],
    redirect: registerokRM0MfOvMMeta?.redirect,
    component: () => import("/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/register.vue").then(m => m.default || m)
  },
  {
    name: _91token_93CsJlIAfDCMMeta?.name ?? "wachtwoord-reset-token",
    path: _91token_93CsJlIAfDCMMeta?.path ?? "/wachtwoord-reset/:token()",
    meta: _91token_93CsJlIAfDCMMeta || {},
    alias: _91token_93CsJlIAfDCMMeta?.alias || [],
    redirect: _91token_93CsJlIAfDCMMeta?.redirect,
    component: () => import("/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/wachtwoord-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: aanvraagPbjGJIFBc3Meta?.name ?? "wachtwoord-reset-aanvraag",
    path: aanvraagPbjGJIFBc3Meta?.path ?? "/wachtwoord-reset/aanvraag",
    meta: aanvraagPbjGJIFBc3Meta || {},
    alias: aanvraagPbjGJIFBc3Meta?.alias || [],
    redirect: aanvraagPbjGJIFBc3Meta?.redirect,
    component: () => import("/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/wachtwoord-reset/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: zoekenW11G0Ht40rMeta?.name ?? "zoeken",
    path: zoekenW11G0Ht40rMeta?.path ?? "/zoeken",
    meta: zoekenW11G0Ht40rMeta || {},
    alias: zoekenW11G0Ht40rMeta?.alias || [],
    redirect: zoekenW11G0Ht40rMeta?.redirect,
    component: () => import("/data/www/foodyard.nl/bidfood-foodyard/nuxt/pages/zoeken.vue").then(m => m.default || m)
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.path ?? "/thema/producten-en-bereidingen/vis/garnalen-de-verschillende-soorten-op-een-rij",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPX
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.path ?? "/sessions",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPX
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.path ?? "/contact",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPX
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.path ?? "/over-foodyard",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPX
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.path ?? "/sessions/wilddagen",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPX
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.path ?? "/sessions/fermenteren-christian-weij",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPX
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.path ?? "/sessions/de-kansen-van-bier-en-food",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPX
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.path ?? "/sessions/unique-food-concepts",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPX
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.path ?? "/sessions/dim-sum-de-authentieke-chinese-delicatesse-2024",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPX
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.path ?? "/sessions/werken-met-onze-merken",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPX
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.path ?? "/thema",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPX
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.path ?? "/academy",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPX
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.path ?? "/inloggen",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPX
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.path ?? "/foodyard-headlines",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPX
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.path ?? "/registreren?intended=/",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPXMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6422_465_461_encoding_640_461_4613_eslint_648_4657_460_ioredis_645ZDnqkCCAPX
  }
]