<template>
  <div>
    <h2
      class="relative mb-8 w-full text-nowrap text-center uppercase leading-none text-white"
      :style="{'font-size': fontSizeScreen}"
    >
      {{ text }}
      <span
        class="absolute inset-x-0 bottom-0 font-Shockwave leading-normal"
        :class="[color === 'blue' ? 'text-tertiary' : 'text-primary']"
        :style="{'font-size': fontSizeSub}"
      >
        {{ text }}
      </span>
    </h2>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  text: string
  color: 'blue' | 'green'
}>();

const fontSizeScreen = ref('4rem');
const fontSizeSub = ref('4rem');

onMounted(() => {
  fontSizeScreen.value = `clamp(1vw, calc(230vw / ${props.text?.length || 1}), 30vw)`;
  fontSizeSub.value = `clamp(1vw, calc(100vw / ${props.text?.length || 1}), 25vw)`;
});
</script>
